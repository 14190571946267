// images.js
import Carousel1_1 from "./carousel1_1.png";
import Carousel1_2 from "./carousel1_2.png";
import Carousel1_3 from "./carousel1_3.png";
import Carousel1_4 from "./carousel1_4.png";
import Carousel2_1 from "./carousel2_1.png";
import Carousel2_2 from "./carousel2_2.png";
import Carousel2_3 from "./carousel2_3.png";
import Carousel2_4 from "./carousel2_4.png";
import Carousel3_1 from "./carousel3_1.png";
import Carousel3_2 from "./carousel3_2.png";
import Carousel3_3 from "./carousel3_3.png";
import Carousel3_4 from "./carousel3_4.png";
import Carousel4_1 from "./carousel4_1.png";
import Carousel4_2 from "./carousel4_2.png";
import Carousel4_3 from "./carousel4_3.png";
import Carousel4_4 from "./carousel4_4.png";
import Carousel5_1 from "./carousel5_1.png";
import Carousel5_2 from "./carousel5_2.png";
import Carousel5_3 from "./carousel5_3.png";
import Carousel5_4 from "./carousel5_4.png";
import Carousel6_1 from "./carousel6_1.png";
import Carousel6_2 from "./carousel6_2.png";
import Carousel6_3 from "./carousel6_3.png";
import Carousel6_4 from "./carousel6_4.png";
import Carousel7_1 from "./carousel7_1.png";
import Carousel7_2 from "./carousel7_2.png";
import Carousel7_3 from "./carousel7_3.png";
import Carousel7_4 from "./carousel7_4.png";
import Carousel8_1 from "./carousel8_1.png";
import Carousel8_2 from "./carousel8_2.png";
import Carousel8_3 from "./carousel8_3.png";
import Carousel8_4 from "./carousel8_4.png";
import Orientation_1 from "./disco.png";
import Orientation_2 from "./disco2.png";
import Orientation_3 from "./disco3.png";
import Gender_1 from "./gender1.png";
import Gender_2 from "./gender2.png";
import Gender_3 from "./gender3.png";
import Gender_4 from "./gender4.png";
import Hobby_1 from "./hobby_arts.png";
import Hobby_2 from "./hobby_collecting.png";
import Hobby_3 from "./hobby_cooking.png";
import Hobby_4 from "./hobby_crafting.png";
import Hobby_5 from "./hobby_dancing.png";
import Hobby_6 from "./hobby_education.png";
import Hobby_7 from "./hobby_fitness.png";
import Hobby_8 from "./hobby_gaming.png";
import Hobby_9 from "./hobby_gardening.png";
import Hobby_10 from "./hobby_meditation.png";
import Hobby_11 from "./hobby_music.png";
import Hobby_12 from "./hobby_other.png";
import Hobby_13 from "./hobby_photography.png";
import Hobby_14 from "./hobby_reading.png";
import Hobby_15 from "./hobby_sports.png";
import Hobby_16 from "./hobby_technology.png";
import Hobby_17 from "./hobby_travel.png";
import Hobby_18 from "./hobby_unknown.png";
import Hobby_19 from "./hobby_volunteering.png";
import Hobby_20 from "./hobby_writing.png";
export const carousel_1 = [Carousel1_1, Carousel1_2, Carousel1_3, Carousel1_4];
export const carousel_2 = [Carousel2_1, Carousel2_2, Carousel2_3, Carousel2_4];
export const carousel_3 = [Carousel3_1, Carousel3_2, Carousel3_3, Carousel3_4];
export const carousel_4 = [Carousel4_1, Carousel4_2, Carousel4_3, Carousel4_4];
export const carousel_5 = [Carousel5_1, Carousel5_2, Carousel5_3, Carousel5_4];
export const carousel_6 = [Carousel6_1, Carousel6_2, Carousel6_3, Carousel6_4];
export const carousel_7 = [Carousel7_1, Carousel7_2, Carousel7_3, Carousel7_4];
export const carousel_8 = [Carousel8_1, Carousel8_2, Carousel8_3, Carousel8_4];
export const orientationTypes =[Orientation_1, Orientation_2,Orientation_3];
export const genderTypes =[Gender_1,Gender_2,Gender_3,Gender_4];
export const hobbyTypes =[Hobby_1,Hobby_2,Hobby_3,Hobby_4,Hobby_5,Hobby_6,Hobby_7,Hobby_8,Hobby_9,Hobby_10,Hobby_11,Hobby_12,Hobby_13,Hobby_14,Hobby_15,Hobby_16,Hobby_17,Hobby_18,Hobby_19,Hobby_20];
