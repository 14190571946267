export const version1Orientations = ["Heterosexual", "Lesbian", "Homosexual"];
export const version1Gender = ["Female", "Male", "Masculine", "Feminine"];
export const version1Keys = [
  "Other (Not Listed)",
  "Nourish or be nourished",
  "Exchange of power",
  "Nourish or be nourished",
  "Pets",
  "Domestic Bottom",
  "Financial Sub",
  "Rope Bunny"
];

export const version1Hobbies = [
  "Arts",
  "Collecting",
  "Cooking",
  "Crafting",
  "Dance",
  "Education",
  "Fitness",
  "Gaming",
  "Gardening",
  "Meditation",
  "Music",
  "Other",
  "Photography",
  "Reading",
  "Sports",
  "Technology",
  "Travel",
  "The Unknown",
  "Volunteering",
  "Writing",
];
